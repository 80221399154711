import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, merge, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { AppInsightsService } from '../services-index';

@Injectable()
export class RemoteCacheService {
  
  constructor(
    private http: HttpClient,
    private appInsightsService: AppInsightsService
  ) { }

  purgeAllRemoteCaches(): Observable<{ version: string, result: Object }> {
    return merge(
      this.purgeRemoteV4Cache().pipe(map(result => ({ version: 'v4', result }))),
      this.purgeRemoteV5Cache().pipe(map(result => ({ version: 'v5', result })))
    );
  }

  purgeRemoteV5Cache(): Observable<Object> {
    this.appInsightsService.trackEvent("RequestSentToClearV5Cache");

    return this.http.post(environment.baseV5ApiUri + '/Cache/clear', {})
      .pipe(
        catchError(message => of(new Error(message))) // If it's an error, pass it forward to log
      );
  }

  purgeRemoteV4Cache(): Observable<Object> {
    this.appInsightsService.trackEvent("RequestSentToClearV4Cache");

    return this.http.post(environment.baseApiUri + '/clearCache', {})
      .pipe(
        catchError(message => of(new Error(message))) // If it's an error, pass it forward to log
      );
  }
}
