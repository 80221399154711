import { Injectable } from '@angular/core';
import { Enums, IEnums } from '../../enums/enums';

interface ILocalCacheService {
    retrieveLocalCache<T>(storageKey: string): T;
    setLocalCache<T>(storageKey: string, cacheData: T): void;
    purgeLocalCache(storageKey: string): void;
    purgeAllLocalCaches(): void;
}

@Injectable()
export class LocalCacheService implements ILocalCacheService {
    private enums: IEnums = Enums;

    private localStorage = new LocalStorageService();
    
    retrieveLocalCache<T>(storageKey: string): T {
        return <T>this.localStorage.get(storageKey);
    }

    setLocalCache<T>(storageKey: string, cacheData: T): void {
        this.localStorage.set(storageKey, cacheData);
    }

    purgeLocalCache(storageKey: string): void {
        this.localStorage.remove(storageKey);
    }

    purgeAllLocalCaches(): void {
        this.localStorage.remove('__app_storage__');
        this.localStorage.remove(this.enums.cacheKeys.authData);
        this.localStorage.remove(this.enums.cacheKeys.rememberMe);
        this.localStorage.remove(this.enums.cacheKeys.currentFilter);
        this.localStorage.remove(this.enums.cacheKeys.defaultFilter);
        this.localStorage.remove(this.enums.cacheKeys.orgLookups);
        this.localStorage.remove(this.enums.cacheKeys.salesUnit);
    }
}

class LocalStorageService {
    get(key: string) {
        const jsonString = localStorage.getItem(key);
        return JSON.parse(jsonString);
    }

    set(key: string, value: any) {
        const jsonString = JSON.stringify(value);
        localStorage.setItem(key, jsonString);
    }

    remove(key: string) {
        localStorage.removeItem(key);
    }
}
