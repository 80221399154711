import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { AppInsightsService } from '../appInsights/appInsights.service';
import { LocalCacheService } from '../cache/localCache.service';
import { Enums, IEnums } from '../../enums/enums';
import { environment } from '../../../../environments/environment';
import * as Models from '../../models/models-index';
import { tap, map, catchError } from 'rxjs/operators';
import { isLoggedIn, selectLoggedInUser, selectRouter } from '../../../_store/app.selectors';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../_store/app-state.model';

@Injectable({providedIn: 'root'})
export class AuthenticationService {
    private enums: IEnums = Enums;
    private serviceBase = undefined;
    isLoggedIn$ = this.store.pipe(select(isLoggedIn));
    loggedInUser$ = this.store.pipe(select(selectLoggedInUser));
    routeParams$ = this.store.pipe(select(selectRouter));

    constructor(
        private store: Store<AppState>,
        private http: HttpClient,
        private cache: LocalCacheService,
        private appInsights: AppInsightsService)
    {
        this.serviceBase = environment.baseApiUri;
    }

    getUser(): Models.IAuthenticationInfo {
        const userString = localStorage.getItem('user');
        return userString ? <Models.IAuthenticationInfo>JSON.parse(userString) : null;
    }

    resetUser() {
        localStorage.removeItem('user');
    }

    register(user: Models.IUser): Observable<any> {
        return this.http.post(this.serviceBase + '/account/register', user)
        .pipe(
            catchError(err => {
              appInsights.trackException(err);
              this.handleError(err)
              return of(err);
            })
        );
    }

    changePassword(oldPassword: string, newPassword: string, confirmPassword: string): Observable<any> {
        return this.http.post(this.serviceBase + '/account/changepassword', {
            oldPassword: oldPassword,
            password: newPassword,
            confirmPassword: confirmPassword
        }).pipe(
          catchError(err => {
              appInsights.trackException(err);
              this.handleError(err)
              return of(err);
            })
        );
    }

    resetPassword(userName: string, newPassword: string, confirmPassword: string): Observable<any> {
        return this.http.post(this.serviceBase + '/account/resetpassword', {
            userName: userName,
            password: newPassword,
            confirmPassword: confirmPassword
        }).pipe(
            catchError(err => {
                appInsights.trackException(err);
                this.handleError(err)
                return of(err);
            })
        );
    }

    login(userName: string, password: string, roleEntity?: string, dealerGroup?: string): Observable<Models.IAuthenticationInfo> {
        const data = 'grant_type=password&username=' + encodeURIComponent(userName) + '&password=' + encodeURIComponent(password);
        const headers = new HttpHeaders();
        const dealerGroupParameter = !!dealerGroup ? `?dealerGroup=${dealerGroup}` : '';
        headers.append('Content-Type', 'application/x-www-form-urlencoded');


        return this.http.post<any>(environment.authUri + dealerGroupParameter, data, { headers: headers }).pipe(
                    tap(response => {
                        this.appInsights.trackEvent('LoginSuccess', {
                            props: {
                                UserName: userName,
                                RoleEntity: roleEntity || response.roleEntity,
                                UserRole: response.roleIds,
                            }
                        });
                    }),
                    map(response => {
                        const authenticationData: Models.IAuthenticationInfo = {
                            isAuthenticated: true,
                            userName: userName,
                            userId: parseInt(response.userId, 10),
                            firstName: response.firstName,
                            lastName: response.lastName,
                            token: response.access_token,
                            role: response.roles,
                            roles: response.roles,
                            roleIds: response.roleIds,
                            orgLookupTypeIds: response.orgLookupTypeIds,
                            roleEntity: roleEntity || response.roleEntity,
                            dealerGroup: response.dealerGroup,
                            clientUserName: response.userName,
                            userSettings: response.userSettings,
                            locale: response.locale,
                            expireDate: new Date(response['.expires']), 
                        };

                        localStorage.setItem('user', JSON.stringify(authenticationData));

                        return authenticationData;
                    }),
                    catchError(err => {
                        this.appInsights.trackEvent('LoginError', {
                            props: { userName: userName, error: JSON.stringify(err) }
                        });
                        return throwError(() => err);
                    })
                );
    }

    ssoLogin(response: any, roleEntity: string, firstName: string, lastName: string, clientUserName?: string, userSettings?: string): any {
        this.cache.purgeAllLocalCaches();
        const authenticationData: Models.IAuthenticationData = {
            userName: response.userName,
            userId: parseInt(response.userId, 10),
            firstName: firstName,
            lastName: lastName,
            token: response.access_token,
            role: response.roles,
            roles: response.roles,
            roleIds: response.roleIds,
            orgLookupTypeIds: response.orgLookupTypeIds,
            roleEntity: roleEntity,
            clientUserName: clientUserName,
            dealerGroup: response.dealerGroup,
            expireDate: new Date(response['.expires']),
        };

        return authenticationData;

    }

    getAuthInfo(response: any): Models.IAuthenticationInfo {

        const authenticationData: Models.IAuthenticationInfo = {
            isAuthenticated: true,
            userName: response.userName,
            userId: parseInt(response.userId, 10),
            firstName: response.firstName,
            lastName: response.lastName,
            token: response.access_token,
            role: response.roles,
            roles: response.roles,
            roleIds: response.roleIds,
            roleEntity: response.roleEntity,
            orgLookupTypeIds: response.orgLookupTypeIds,
            dealerGroup: response.dealerGroup,
            clientUserName: response.userName,
            userSettings: response.userSettings,
            locale: response.locale,
            expireDate: new Date(response['.expires']),
        };

        this.appInsights.trackEvent('LoginSuccess', {
            props: { userName: response.userName }
        });

        localStorage.setItem('user', JSON.stringify(authenticationData));

        return authenticationData;
    }



    updateAuthenticationInfo(newInfo: Models.IAuthenticationInfo, force?: boolean) {
        // if (force || !this.authenticationInfo || JSON.stringify(this.authenticationInfo) !== JSON.stringify(newInfo)) {
        // 	this.authenticationInfo = newInfo;
        // 	this.authInfoSubject.next(newInfo);
        // 	this.authInfoChanged.next(true);
        // }
    }

    updateAuthenticationInfoExternal(newInfo: Models.IAuthenticationInfo) {
        this.updateAuthenticationInfo(newInfo, true);

        const authenticationData: Models.IAuthenticationData = {
            userName: newInfo.userName,
            userId: newInfo.userId,
            firstName: newInfo.firstName,
            lastName: newInfo.lastName,
            token: newInfo.token,
            role: newInfo.roles,
            roles: newInfo.roles,
            roleIds: newInfo.roleIds,
            orgLookupTypeIds: newInfo.orgLookupTypeIds,
            roleEntity: newInfo.roleEntity,
            clientUserName: newInfo.clientUserName,
            dealerGroup: newInfo.dealerGroup,
            expireDate: newInfo.expireDate,
        };

        this.cache.setLocalCache<Models.IAuthenticationData>(this.enums.cacheKeys.authData, authenticationData);
    }

    // updateStoredUserNames(firstName: string, lastName: string) {
    // 	const currentAuthInfo = this.getAuthenticationInfo();
    // 	currentAuthInfo.firstName = firstName;
    // 	currentAuthInfo.lastName = lastName;
    // 	this.updateAuthenticationInfoExternal(currentAuthInfo);
    // }

    updateUser(user: Models.IUser): Observable<any> {
        return this.http.post(this.serviceBase + '/account/updateUser', user)
        .pipe(
            catchError(err => {
                appInsights.trackException(err);
                return  this.handleError(err);
            })
        );
    }

    private handleError(err: any) {
        let errorMessage: string;
        if (err.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            errorMessage = `Backend returned code ${err.status}: ${err.body.error}`;
        }
        this.appInsights.trackException(err);
        console.error(err);
        return throwError(() => errorMessage);
    }
}
